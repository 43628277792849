import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal, FormControl, Checkbox } from 'react-bootstrap'
import { closeAddDictionaryEntryModal } from './slices'
import joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'
import { useForm, Controller } from 'react-hook-form'
import InputField from 'components/FormInputComponents/InputField'
import Loader from 'components/Loader'
import { useAddEntryMutation } from './cmeDictionaryAPI'
import CategorySuggestion from 'components/Suggestion/CategorySuggestion'
import BrandSuggestion from 'components/Suggestion/BrandSuggestion'

const CMEDictionaryAddEntryModal = () => {
  const dispatch = useDispatch()
  const { show } = useSelector(state => state.cmeDictionary.addDictionaryEntryModal)

  const [addEntry, { isError, error, isLoading: isAdding }] = useAddEntryMutation()

  const onCloseWindow = () => {
    dispatch(closeAddDictionaryEntryModal())
  }

  const validationSchema = joi.object({
    merchantId: joi
      .number()
      .integer()
      .required()
      .min(0)
      .label('MerchantId'),

    description: joi
      .string()
      .regex(/^[a-z0-9 :]+$/i)
      .required()
      .label('Description'),

    category: joi
      .object({
        category_id: joi
          .number()
          .integer()
          .min(0),
        category_full_path: joi.string().allow('')
      })
      .label('Category'),

    brand: joi
      .object({
        brand_id: joi
          .number()
          .integer()
          .min(0),
        brand_full_path: joi.string().allow('')
      })
      .label('Brand'),

    train: joi
      .boolean()
      .default(false)
      .label('Train')
  })

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors, isValid },
    reset,
    setValue
  } = useForm({
    mode: 'all',
    defaultValues: {
      merchantId: 0,
      description: '',
      category: {
        category_id: 0,
        category_full_path: ''
      },

      brand: {
        brand_id: 0,
        brand_full_path: ''
      },

      train: true
    },
    resolver: joiResolver(validationSchema)
  })

  const onSubmit = async data => {
    const { merchantId, description, category, brand, train } = data
    const result = await addEntry({
      merchantId,
      description,
      category,
      brand,
      train
    }).unwrap()
    if (result.id) {
      closeModal()
    }
  }

  const handleUseInTrainingChange = event => {
    const value = event.target.checked
    setValue('train', value, {
      shouldValidate: true
    })
  }

  const closeModal = () => {
    dispatch(closeAddDictionaryEntryModal())
  }

  return (
    <Modal show={show} onHide={onCloseWindow} bsSize="lg">
      <Modal.Header>
        <Modal.Title>Add Dictionary Entry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="mt-3 d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
          <InputField
            label="Merchant ID"
            required
            info="enter merchant ID"
            className="text-start"
            labelSize={3}
            validationMessage={errors.merchantId?.message}
          >
            <Controller
              required
              name="merchantId"
              control={control}
              render={({ field }) => <FormControl {...field} />}
            />
          </InputField>

          <InputField
            label="Description"
            required
            info="enter description"
            className="text-start"
            labelSize={3}
            validationMessage={errors.description?.message}
          >
            <Controller
              required
              name="description"
              control={control}
              render={({ field }) => <FormControl {...field} />}
            />
          </InputField>

          <InputField
            className="text-start"
            label="Category"
            info="Select category"
            labelSize={3}
            validationMessage={errors.categoryId?.message}
          >
            <Controller
              name="category"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CategorySuggestion
                  selectedId={value.category_id}
                  selectedLabel={value.category_full_path}
                  isLeaf
                  changeCallback={category =>
                    onChange({ category_id: category?.id || 0, category_full_path: category?.fullPath || '' })
                  }
                />
              )}
            />
          </InputField>

          <InputField
            className="text-start"
            label="Brand"
            info="Select brand"
            labelSize={3}
            validationMessage={errors.brandId?.message}
          >
            <Controller
              name="brand"
              control={control}
              render={({ field: { onChange, value } }) => (
                <BrandSuggestion
                  selectedId={value.brand_id}
                  selectedLabel={value.brand_full_path}
                  isLeaf
                  changeCallback={brand =>
                    onChange({ brand_id: brand?.id || 0, brand_full_path: brand?.fullPath || '' })
                  }
                />
              )}
            />
          </InputField>

          <InputField
            label="Train"
            info="whether this rule should be used in training?"
            className="text-start"
            labelSize={3}
            fieldSize={1}
            validationMessage={errors.train?.message}
          >
            <Controller
              name="train"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox className="text-center" onChange={handleUseInTrainingChange} checked={value} />
              )}
            />
          </InputField>

          <div className="mt-4 d-flex justify-content-center">
            {isAdding ? (
              <Loader height={40} />
            ) : (
              <>
                <Button onClick={closeModal}>Close</Button>

                <Button className="ml-2" onClick={() => reset()} disabled={!isDirty}>
                  Reset
                </Button>

                <Button className="ml-2" type="submit" bsStyle="primary" disabled={!isDirty || (isDirty && !isValid)}>
                  Add to Dictionary
                </Button>
              </>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        {isError && (
          <div className="text-danger">
            <h4>{error.data}</h4>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default CMEDictionaryAddEntryModal

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { connect } from 'react-redux'
import ReactInterval from 'react-interval'
import { permissions as PERMISSIONS } from 'constants/constants'
import constants from '../../../constants/niq-constants'
import {
  onTableSort,
  onToggleDescriptionEdit,
  onToggleSearchLockData,
  onTagForEdit,
  onSaveDictionaryEntries,
  refreshSearchDescriptionData,
  onDescriptionSelectionChanged,
  onBrandSuggest,
  onCategorySuggest,
  onBrandEditSelect,
  onCategoryEditSelect,
  refreshDesciptionEditStatus,
  onBulkStageDictionaryEdits,
  onBulkUnstageDictionaryEdits,
  onToggleToolbarOption,
  onChangeSearchColumnsVisibility,
  onChangeWidgetEnabledStatus,
  toggleDescriptionSearchStatus,
  fetchDescriptionEditSuggestions
} from '../../../actions/niq-widget-actions'
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import '../../../../../styles/descriptionTable.scss'
import DescriptionToolbar from './description-toolbar'
import DescriptionTableOptions from './DescriptionTableOptions'
import Loader from 'components/Loader'
import DescriptionTitleValue from './descriptionTableCells/DescriptionTitleValue'
import EditableDesciptionCell from './descriptionTableCells/EditableDesciptionCell'
import LargeNumValue from './descriptionTableCells/LargeNumValue'
import InEditValue from './descriptionTableCells/InEditValue'
import ColumnFilter from './ColumnFilter'
import { defaultPaginationOptions, searchFields } from '../../../constants/NIQDescriptionTableConfig'
import UserService from '../../../../../services/user-service'
import { findIndex } from 'lodash'
import { DESCRIPTION_EDITABLE_ATTRIBUTE } from './table-constants'

import { NIQ_SEARCH_EDIT_STORE_PATH, NIQ_SEARCH_STORE_PATH } from '../../../reducers/niq-reducers'

const getExportFileName = () => {
  const date = new Date()
  return (
    'descriptions_' +
    `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}_` +
    `${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}` +
    '.csv'
  )
}
function mapStateToProps(state) {
  const niqSearchState = state[NIQ_SEARCH_STORE_PATH]
  const niqSearchEditState = state[NIQ_SEARCH_EDIT_STORE_PATH]
  return {
    data: niqSearchEditState.description.data,
    totalDescriptionsCount: niqSearchEditState?.description?.hits || 0,
    loading: niqSearchEditState.description.loading,
    aggregation: niqSearchEditState.description.aggregation,
    inEdit: niqSearchEditState.description.inEdit,
    edits: niqSearchEditState.description.edits,
    stagedEdits: niqSearchEditState.description.stagedEdits,
    filters: niqSearchState.searchFilters,
    selectedSize: niqSearchEditState.description.selectedSize,
    sortBy: niqSearchEditState.description.sortBy,
    sortOrder: niqSearchEditState.description.sortOrder,
    from: niqSearchState.searchFilters.from,
    to: niqSearchState.searchFilters.to,
    showToolbar: niqSearchEditState.description.showToolbar,
    columns: niqSearchEditState.description.columns,
    stats: niqSearchEditState.stats,
    canEdit: UserService.hasPermission(state.session.user, PERMISSIONS.NIQqcToolEdit),
    widgetEnabledStatus: niqSearchState.widgetEnabledStatus,
    showDescSearch: niqSearchState.showDescSearch
  }
}
const sortOptions = {
  sales: 'Sales'
}

class DescriptionTable extends Component {
  createValueEditor = (onUpdate, props) => {
    const { attribute, row } = props

    if (
      (attribute === DESCRIPTION_EDITABLE_ATTRIBUTE.BRAND && !row.is_brand_editable) ||
      (attribute === DESCRIPTION_EDITABLE_ATTRIBUTE.CATEGORY && !row.is_category_editable)
    ) {
      return (
        <EditableDesciptionCell
          ref={this.inputRef} // needed by BootstrapTable. Please do not remove this.
          cell={attribute === DESCRIPTION_EDITABLE_ATTRIBUTE.BRAND ? row.brand_name : row.category_full_path}
          dictKey={row.dictionary_key}
          inDict={attribute === DESCRIPTION_EDITABLE_ATTRIBUTE.BRAND ? row.in_dict_brand : row.in_dict_category_fp}
          attribute={attribute}
        />
      )
    }

    return <InEditValue onUpdate={onUpdate} attribute={attribute} row={row} defaultValue={props.defaultValue} />
  }

  buildColumns() {
    this.unselectableRows = []

    const getCustomFilter = (filterHandler, { delay, placeholder }) => {
      return <ColumnFilter filterHandler={filterHandler} delay={delay} placeholder={placeholder} />
    }

    const getColumn = column => {
      const width = ''
      const dataFormat = cell => cell
      switch (column) {
        case 'sequence':
          return {
            label: '#',
            field: column,
            width: '20',
            csvFormat: cell => cell,
            hasSort: true,
            thStyle: { verticalAlign: 'top' },
            dataAlign: 'center'
          }
        case 'sales':
          return {
            label: 'Sales',
            field: column,
            dataAlign: 'left',
            dataFormat: cell => <LargeNumValue value={parseFloat(cell)} />,
            width: '50',
            thStyle: { verticalAlign: 'top' },
            hasSort: true,
            sortFunc: (firstInput, secondInput, order) => {
              return order === 'desc'
                ? parseFloat(secondInput.sales) - parseFloat(firstInput.sales)
                : parseFloat(firstInput.sales) - parseFloat(secondInput.sales)
            }
          }
        case 'nankey':
          return {
            label: 'Nankey',
            field: column,
            dataAlign: 'left',
            dataFormat: cell => <LargeNumValue value={parseFloat(cell)} showLongNumber />,
            width: '50',
            thStyle: { verticalAlign: 'top' },
            hasSort: true
          }
        case 'description':
          return {
            width: '120',
            label: 'Description',
            field: column,
            hasSort: true,
            thStyle: { verticalAlign: 'top' },
            filter: {
              type: 'CustomFilter',
              getElement: getCustomFilter,
              customFilterParameters: {
                placeholder: 'Description Search'
              }
            },
            dataFormat: (cell, row) => (
              <DescriptionTitleValue
                descriptionValue={cell}
                merchantName={row.merchant_name ? row.merchant_name : ''}
              />
            )
          }
        case 'merchant_name':
          return {
            width: '70',
            label: 'Merchant',
            field: column,
            hasSort: true,
            thStyle: { verticalAlign: 'top' },
            filter: {
              type: 'CustomFilter',
              getElement: getCustomFilter,
              customFilterParameters: {
                placeholder: 'Merchant Search'
              }
            }
          }

        case 'brand_name':
          return {
            width: '70',
            label: 'Brand',
            field: 'brand_name',
            dataAlign: 'left',
            hasSort: true,
            filter: {
              type: 'CustomFilter',
              getElement: getCustomFilter,
              customFilterParameters: {
                placeholder: 'Brand Search'
              }
            },
            thStyle: { verticalAlign: 'top' },
            dataFormat: (cell, row) => {
              if (row && !row.is_brand_editable && !row.is_category_editable) {
                this.unselectableRows.push(row.sequence)
              }
              return (
                <EditableDesciptionCell
                  cell={cell}
                  updatedBy={row.brand_added_by}
                  dictKey={row.dictionary_key}
                  inDict={row.in_dict_brand}
                  attribute={DESCRIPTION_EDITABLE_ATTRIBUTE.BRAND}
                />
              )
            },
            customEditor: {
              getElement: this.createValueEditor,
              customEditorParameters: { attribute: DESCRIPTION_EDITABLE_ATTRIBUTE.BRAND }
            },
            editColumnClassName: (cell, row) => {
              return row.is_brand_editable ? 'in-edit' : 'not-editable'
            },
            columnClassName: (cell, row) => {
              return row.is_brand_editable ? '' : 'not-editable'
            },
            editable: (cell, row) => row.is_brand_editable
          }
        case 'category_full_path': {
          return {
            width: '140',
            label: 'Category Full Path',
            field: column,
            thStyle: { verticalAlign: 'top' },
            hasSort: true,
            filter: {
              type: 'CustomFilter',
              getElement: getCustomFilter,
              customFilterParameters: {
                placeholder: 'Category Search'
              }
            },
            dataFormat: (cell, row) => {
              return (
                <EditableDesciptionCell
                  cell={cell}
                  updatedBy={row.category_added_by}
                  dictKey={row.dictionary_key}
                  inDict={row.in_dict_category_fp}
                  attribute={DESCRIPTION_EDITABLE_ATTRIBUTE.CATEGORY}
                />
              )
            },
            customEditor: {
              getElement: this.createValueEditor,
              customEditorParameters: { attribute: DESCRIPTION_EDITABLE_ATTRIBUTE.CATEGORY }
            },
            editColumnClassName: (cell, row) => {
              return row.is_category_editable ? 'in-edit' : 'not-editable'
            },
            columnClassName: (cell, row) => {
              return row.is_category_editable ? '' : 'not-editable'
            },
            editable: (cell, row) => row.is_category_editable
          }
        }
        default: {
          break
        }
      }

      return {
        label: constants.fields[column].label,
        field: column,
        dataFormat,
        width,
        hasSort: true
      }
    }

    const columnFields = searchFields.map(field => field.value)
    const fields = ['sequence', ...columnFields]
    const columns = fields.map(field => getColumn(field))
    const headerColumns = columns.map((col, index) => {
      return (
        <TableHeaderColumn
          dataField={col.field}
          hidden={col.field !== 'sequence' && this.props.columns.indexOf(col.field) === -1}
          isKey={col.field === 'sequence'}
          key={`col-${index}`}
          thStyle={col.thStyle}
          dataSort={col.hasSort}
          dataAlign={col.dataAlign}
          dataFormat={col.dataFormat}
          csvFormat={col.csvFormat}
          csvFormatExtraData={col.csvFormatExtraData}
          sortFunc={col.sortFunc}
          customEditor={col.customEditor}
          editColumnClassName={col.editColumnClassName}
          columnClassName={col.columnClassName}
          editable={!!col.editable}
          ref={el => this.tableHeaderRefs(el)}
          width={col.width}
          filter={this.props.showDescSearch ? col.filter : {}}
        >
          {col.label}
        </TableHeaderColumn>
      )
    })
    return headerColumns
  }

  tableHeaderRefs = el => {
    if (!el) {
      return
    }
    if (!this.columnRefs) {
      this.columnRefs = {}
    }
    this.columnRefs[el.props.dataField] = el
  }

  toggleDescriptionSearchStatus = () => {
    for (const ref in this.columnRefs) {
      this.columnRefs[ref].cleanFiltered()
    }
    this.props.toggleDescriptionSearchStatus()
  }

  createField(fieldName, sortType, sortOrder, size, siblingAggs) {
    const field = {}
    field.fieldName = fieldName
    field.orderType = sortType
    field.sort = sortOrder
    field.size = size || 10
    field.siblingAggs = siblingAggs
    return field
  }

  onSelectRow(row, isSelected) {
    this.tagRows([this.convertRow(row)], isSelected)
  }

  convertRow(row) {
    return {
      id: row.dictionary_key,
      merchantId: row.merchant_id,
      normDescription: row.norm_description,
      description: row.description,
      brand_id: row.brand_id,
      brand_full_path: row.brand_name,
      category_id: row.category_id,
      category_full_path: row.category_full_path,
      inDictBrand: row.in_dict_brand,
      inStagingDictBrand: row.in_staging_dict_brand,
      inDictCategory: row.in_dict_category_fp,
      inStagingDictCategory: row.in_staging_dict_category_fp,
      isBrandEditable: row.is_brand_editable,
      isCategoryEditable: row.is_category_editable
    }
  }

  onSelectAllRows(isSelected, selectAll = false) {
    if (this.descriptionTable && this.descriptionTable.getTableDataIgnorePaging) {
      let data
      if (selectAll === true) {
        data = this.descriptionTable.getTableDataIgnorePaging().map(this.convertRow)
      } else {
        data = this.descriptionTable.getTableData().map(this.convertRow)
      }

      if (this.props.edits.rows.length >= this.props.data.length) {
        this.tagRows(data, false)
      } else {
        this.tagRows(data, isSelected)
      }
    }
  }

  tagRows(rows, isSelected) {
    this.props.onTagForEdit(rows, isSelected)
  }

  getOptions = () => {
    const paginationOptions = { ...defaultPaginationOptions }
    paginationOptions.hideSizePerPage = !(this.props.data.length > 0)
    paginationOptions.paginationShowsTotal = this.props.data.length > 0

    return {
      defaultSortName: 'sequence', // default sort column name
      defaultSortOrder: 'asc', // default sort order
      noDataText: (
        <div className="no-data-msg" style={{ fontSize: '18px' }}>
          {this.props.loading ? <Loader /> : 'No results'}
        </div>
      ),
      ...paginationOptions
    }
  }

  getSelectedRowKeys = () => {
    return this.props.edits.rows.map(row => {
      let index = findIndex(this.props.data, ['dictionary_key', row.id])
      return ++index
    })
  }

  selectRow = () => {
    return this.props.inEdit
      ? {
          mode: 'checkbox',
          selected: this.getSelectedRowKeys(),
          clickToSelect: false,
          onSelect: this.onSelectRow.bind(this),
          onSelectAll: this.onSelectAllRows.bind(this),
          unselectable: this.unselectableRows
        }
      : {
          mode: 'checkbox',
          selected: [],
          hideSelectColumn: true
        }
  }

  handleExportCSV = () => {
    // create a helper utility that is outside the table to avoid setting global variable
    if (this.descriptionTable) {
      this.descriptionTable.handleExportCSV()
    }
  }

  getHeightForTable = () => {
    if (this.props.height) {
      let offset = 70 // offset for toolbar + Pagination
      if (this.props.showToolbar) {
        offset += 160 // offset for tableOptions
      }
      return this.props.height - offset
    }
  }

  onChangeWidgetEnabledStatus = () => {
    this.props.onChangeWidgetEnabledStatus(constants.aggregations.DESCRIPTION)
  }

  onSelectAll = () => this.onSelectAllRows(true, true)

  render() {
    if (!this.props.data) return null
    let sequence = 1
    return (
      <div style={{ height: '100%', overflow: 'auto' }}>
        <div className="description-table-actions">
          <DescriptionToolbar
            data={this.props.data}
            loading={this.props.loading}
            totalDescriptionsCount={this.props.totalDescriptionsCount}
            inEdit={this.props.canEdit ? this.props.inEdit : false}
            edits={this.props.edits}
            stagedEdits={this.props.stagedEdits}
            selectedSize={this.props.selectedSize}
            onBrandSelect={this.props.onBrandEditSelect}
            onCategorySelect={this.props.onCategoryEditSelect}
            onBrandSuggest={this.props.onBrandSuggest}
            onCategorySuggest={this.props.onCategorySuggest}
            onToggleDescriptionEdit={this.props.onToggleDescriptionEdit}
            onToggleSearchLockData={this.props.onToggleSearchLockData}
            onBulkStageDictionaryEdits={this.props.onBulkStageDictionaryEdits}
            onBulkUnstageDictionaryEdits={this.props.onBulkUnstageDictionaryEdits}
            onSaveDictionaryEntries={this.props.onSaveDictionaryEntries}
            onExport={this.handleExportCSV}
            onToggleToolbarOption={this.props.onToggleToolbarOption}
            toolbarOptionActive={this.props.showToolbar}
            canEdit={this.props.canEdit}
            tableStatus={this.props.widgetEnabledStatus[constants.aggregations.DESCRIPTION]}
            onChangeStatus={this.onChangeWidgetEnabledStatus}
            refreshDescriptionData={this.props.refreshSearchDescriptionData}
            toggleSearchStatus={this.toggleDescriptionSearchStatus}
            showSearchIcon={this.props.showDescSearch}
            descriptionEditSuggestions={this.props.fetchDescriptionEditSuggestions}
            onSelectAll={() => this.onSelectAll()}
          />
        </div>
        {this.props.loading ? (
          <Loader />
        ) : (
          <div className={`description-table-container`}>
            {
              <DescriptionTableOptions
                onDescriptionSelectionChanged={this.props.onDescriptionSelectionChanged}
                selectedSize={this.props.selectedSize}
                sortBy={this.props.sortBy}
                sortOptions={sortOptions}
                refreshDescriptionData={this.props.refreshSearchDescriptionData}
                from={this.props.from}
                to={this.props.to}
                columnList={searchFields}
                visibleColumns={this.props.columns}
                handleSelectChange={this.props.onChangeSearchColumnsVisibility}
                showToolbar={this.props.showToolbar}
              />
            }
            <BootstrapTable
              ref={el => {
                this.descriptionTable = el
              }}
              data={this.props.data.map(row => ({ ...row, sequence: sequence++ }))}
              height={this.getHeightForTable()}
              pagination
              options={this.getOptions()}
              selectRow={this.selectRow()}
              cellEdit={
                this.props.canEdit
                  ? {
                      mode: 'click',
                      blurToSave: true
                    }
                  : {}
              }
              csvFileName={getExportFileName()}
              striped
              hover
              condensed
              bordered
              responsive
            >
              {this.buildColumns()}
            </BootstrapTable>
          </div>
        )}
        {this.props.data && this.props.data.length > 0 && (
          <ReactInterval timeout={300000} enabled callback={() => this.props.refreshDesciptionEditStatus()} />
        )}
      </div>
    )
  }
}

DescriptionTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  totalDescriptionsCount: PropTypes.number,
  inEdit: PropTypes.bool,
  edits: PropTypes.object,
  stagedEdits: PropTypes.object,
  onTableSort: PropTypes.func,
  aggregation: PropTypes.arrayOf(PropTypes.object),
  onTagForEdit: PropTypes.func,
  onToggleDescriptionEdit: PropTypes.func,
  onDescriptionSelectionChanged: PropTypes.func,
  onToggleSearchLockData: PropTypes.func,
  onSaveDictionaryEntries: PropTypes.func,
  onBulkStageDictionaryEdits: PropTypes.func,
  onBulkUnstageDictionaryEdits: PropTypes.func,
  onBrandEditSelect: PropTypes.func,
  onCategoryEditSelect: PropTypes.func,
  onBrandSuggest: PropTypes.func,
  onCategorySuggest: PropTypes.func,
  loading: PropTypes.bool,
  selectedSize: PropTypes.number,
  refreshDesciptionEditStatus: PropTypes.func,
  refreshSearchDescriptionData: PropTypes.func,
  sortBy: PropTypes.string,
  to: PropTypes.string,
  from: PropTypes.string,
  onToggleToolbarOption: PropTypes.func,
  showToolbar: PropTypes.bool,
  onChangeSearchColumnsVisibility: PropTypes.func,
  columns: PropTypes.array,
  height: PropTypes.number,
  stats: PropTypes.object,
  canEdit: PropTypes.bool,
  widgetEnabledStatus: PropTypes.object,
  onChangeWidgetEnabledStatus: PropTypes.func,
  toggleDescriptionSearchStatus: PropTypes.func,
  showDescSearch: PropTypes.bool,
  fetchDescriptionEditSuggestions: PropTypes.func
}

export default connect(mapStateToProps, {
  onTableSort,
  onToggleDescriptionEdit,
  onToggleSearchLockData,
  onTagForEdit,
  onBulkStageDictionaryEdits,
  onBulkUnstageDictionaryEdits,
  onSaveDictionaryEntries,
  onBrandSuggest,
  onCategorySuggest,
  onBrandEditSelect,
  onCategoryEditSelect,
  refreshDesciptionEditStatus,
  onDescriptionSelectionChanged,
  refreshSearchDescriptionData,
  onToggleToolbarOption,
  onChangeSearchColumnsVisibility,
  onChangeWidgetEnabledStatus,
  toggleDescriptionSearchStatus,
  fetchDescriptionEditSuggestions
})(DescriptionTable)
